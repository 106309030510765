/* TODO: Fix naming conventions and separate these classes into different files */


.App {
  text-align: center;
  background-color: #444653;
    width: 100%;
    display: inline-flex;
}

.sidebar {
    /*width: 20vw;*/
    /*min-width: 250px;*/
    /*max-width: 500px;*/
    width: 260px;

    /*position: sticky;*/
    /*height: 100vh;*/
    background: #202123;
    /*flex: 1;*/
    /*display: flex;*/
    flex-direction: column;
    text-align: center;
}
.sidebar h2 {
    width: 100%;
}

.sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    width: 100%;
    background-color: #202123;
    color: #eee;
    font-size: 14px;
}

.bookmark-sidebar {
    /*width: 20vw;*/
    /*min-width: 250px;*/
    /*max-width: 500px;*/
    width: 260px;

    /*position: sticky;*/
    /*height: 100vh;*/
    background: #202123;
    /*flex: 1;*/
    /*display: flex;*/
    flex-direction: column;
    text-align: center;
}

.bookmark-sidebar h2 {
    width: 100%;
}

.bookmark-sidebar-header {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 10px 0;
    width: 100%;
    background-color: #202123;
    color: #eee;
    font-size: 14px;
}

.new-search {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: left;
    height: 40px;
    border-radius: 4px;
    padding: 0 8px;
    margin: 10px 8px;
    background-color: #202123;
    border: #39393b 1px solid;
    color: #eee;
    font-size: 14px;
}

.conversation-item {
    position: relative;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    /*height: 40/px;*/
    padding: 10px 20px;
    margin: 10px 10px;
    border-radius: 4px;
    background-color: #343540;
    color: #eee;
    font-size: 14px;
    border-bottom: #333 1px solid;
}
.conversation-item p {
    margin: 0;
    padding: 2px 10px;
    font-size: 14px;
    text-wrap: none;
}

.App-header {
    min-height: 100vh;
    top: 0;
    width: calc(100svw - 260px);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.errorMessage {
  color: red;
  font-weight: bolder;
}

.input-bar {
    background-color: #343540;
    padding: 25px;
    margin-top: 0;
    width: 100%;
}

.keywords {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 30px 10%;
    width: 80%;
    /*height: 200px;*/
    height: min-content;
    padding: 15px 0;
    border-radius: 16px;
    background-color: #00272B40;
}

.keywords div {
    margin: 6px 5px;
    font-size: 12px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-align: center;
    height: 30px;
    width: 100%;
}

.keywords div .thumbsUp {
    position: absolute;
    right: 1px;
    background-color: #00ff0030;
    text-decoration: none;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px 4px;
    margin: 0 10px 0 auto;
    font-size: 14px;
    border: none;
    height: 30px;
}

.keywords div .thumbsDown {
    position: absolute;
    left: 1px;
    background-color: #ff000030;
    text-decoration: none;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px 4px;
    margin: 0 auto 0 10px;
    font-size: 14px;
    border: none;
    height: 30px;
}

.keywords div span {
    margin: 0;
    font-size: 12px;
    height: 30px;
    font-weight: 600;
    width: 50%;
}

.giftList {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin: 10px;
    max-width: 1200px;
    width: 90%;

    /*  Create grid of 3 columns of gift cards */
    display: grid;
    grid-template-columns: repeat(3, 32%);
    grid-auto-rows: auto;
    grid-gap: 0 10px;
}

.giftCard {
    /* Each card in column has equal width and are spaced equally in parent container*/
    position: relative;
    background-color: rgba(83, 93, 93, 0.5);
    border-radius: 8px;
    margin: 10px;
    width: 100%;
    height: 95%;
    display: flex;
    flex-direction: column;
    flex: 1;
}

.giftCard h1 {
    font-size: 18px;
    color: #fff;
    height: 30px;
    text-align: center;
    width: 72%;
    margin-left: 14%;
}

.giftCard .description {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    /*margin-bottom: 60px;*/
    line-height: 14px;
    margin-top: 4px;
    height: 70px;

    padding: 10px 10px;
}

.giftCard .category {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    height: 40px;
}

.giftCard .amazonLink {
    background-color: rgba(53, 69, 79, 0.8);
    color: orange;
    text-decoration: none;
    font-weight: 600;
    border-radius: 8px;
    padding: 10px;
    margin: 4px 10px 10px;
    font-size: 14px;
}

.giftCard .bookmarkButton {
    background-color: rgba(53, 69, 79, 0.8);
    position: absolute;
    /*right: 10px;*/
    /*top: 10px;*/

    border-radius: 6px;
    padding: 8px;
    /*margin: 4px 10px 10px;*/
}

.giftCard .price {
    font-size: 20px;
}

.reactionContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 40px;
    border-radius: 20px;
    padding: 0 12px;

    max-width: 80%;
    flex-wrap: wrap;
}

.reactionContainer span {
    font-size: 14px;
}

.reactionContainer .reaction {
    position: relative;
    color: #ECC30B;
    border-radius: 20px;
    padding: 2px 5px;
    margin: 0 2px 0 0;
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    font-size: 14px;
}

.feedback-bar {
    position: sticky;
    display: flex;
    bottom: 0;
    width: 100%;
    /*height: 50px;*/
    /*max-height: 50px;*/
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background: #40414E;
    padding: 0 12px;
    flex-wrap: wrap;
    border-top: #565769 1px solid;
}
